import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ProtectedRoute } from "./PageContext";
import { protectedRoute, unProtectedRoutes } from "./constants/routes";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RainbowKitProvider, Theme, darkTheme } from "@rainbow-me/rainbowkit";
import { config } from "./constants/config";
import merge from "lodash.merge";
import "@rainbow-me/rainbowkit/styles.css";

const queryClient = new QueryClient();
const myTheme = merge(darkTheme(), {
  colors: {
    modalBackground: "rgb(19 27 77)",
  },
} as Theme);
function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={myTheme}>
          {/* Your App */}

          <div
            x-data="
      {
        scrolledFromTop: false
      }
    "
            x-init="window.pageYOffset >= 50 ? scrolledFromTop = true : scrolledFromTop = false"
            // @scroll.window="window.pageYOffset >= 50 ? scrolledFromTop = true : scrolledFromTop = false"
            className={`kf fl`}
          >
            <Router>
              <Routes>
                {unProtectedRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  ></Route>
                ))}

                <Route
                  path={"/dashboard"}
                  element={<Navigate to={"/dashboard/courses"} />}
                />
                <Route element={<ProtectedRoute />}>
                  {protectedRoute.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.component />}
                    ></Route>
                  ))}
                </Route>
              </Routes>
            </Router>
            <a
              x-show="scrolledFromTop"
              href="javascript:void(0)"
              data-name="scrollToTop"
              aria-label="scrollToTop"
              className="zq back-to-top c p q r fa qb ac pc be de me of zi kj rj"
              onClick={() => window.scrollTo(0, 0)}
            >
              <span className="cb bc qc sd we xe cf"></span>
            </a>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
