import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";

export default function Presale() {
  return (
    <>
      <Navbar />

      <section className="e ca ci di">
        <div className="a">
          <div className="ja qb _d">
            <div className="jc ng">
              <div
                className="wow fadeInUp la cd pe re gf kf mg yk gl vm"
                data-wow-delay="0s"
              >
                <h3 className="va fi mi pi yi vl gn">Presale</h3>
                <p className="lb fi ii qi _i">Gain access before others.</p>

                <form>
                  <div className="xa position-relative">
                    <button
                      type="button"
                      className="qb be de oe re ze vg wg ii oi xi qj ek fk lk zk vl am fm lg:px-4 xl:px-8 max-btn"
                    >
                      max
                    </button>
                    <label htmlFor="email" className="va ob hi qi aj vl">
                      <div className="d-flex token-label-wrapper">
                        <span>BNB</span>
                        <span>
                          <svg
                            width="74px"
                            height="74px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <g fill="none">
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="16"
                                  fill="#F3BA2F"
                                ></circle>
                                <path
                                  fill="#FFF"
                                  d="M12.116 14.404L16 10.52l3.886 3.886 2.26-2.26L16 6l-6.144 6.144 2.26 2.26zM6 16l2.26-2.26L10.52 16l-2.26 2.26L6 16zm6.116 1.596L16 21.48l3.886-3.886 2.26 2.259L16 26l-6.144-6.144-.003-.003 2.263-2.257zM21.48 16l2.26-2.26L26 16l-2.26 2.26L21.48 16zm-3.188-.002h.002V16L16 18.294l-2.291-2.29-.004-.004.004-.003.401-.402.195-.195L16 13.706l2.293 2.293z"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      name="bnb"
                      placeholder="Enter BNB Amount"
                      defaultValue={"0.00"}
                      className="br cr jc oe re af if zg sg ii _i ej lj pk rk el ql"
                    />
                  </div>
                  <div className="xa">
                    <label htmlFor="password" className="va ob hi qi aj vl">
                      <div className="d-flex token-label-wrapper">
                        <span> Chain Void</span>
                        <span>
                          <svg
                            width="64px"
                            height="64px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <g fill="none">
                                <circle
                                  cx="16"
                                  cy="16"
                                  fill="#6916ff"
                                  r="16"
                                ></circle>
                                <g fill="#ffffff">
                                  <path
                                    d="M17.144 6.424a.28.28 0 01-.081-.363.289.289 0 01.312 0 5.63 5.63 0 011.875 1.272c.303.281.557.609.75.97.204.368.371.753.5 1.152.123.413.187.842.188 1.272-.063.425-.125 2.485-1.313 3.455a.714.714 0 01-.938-.182.627.627 0 01-.062-.727A5.047 5.047 0 0018.5 8c-.4-.566-.854-1.093-1.356-1.576zM15.3 25.006c.125.121.125.242.063.303s-.188.121-.25.06a5.63 5.63 0 01-1.875-1.272 3.709 3.709 0 01-.75-.97 6.463 6.463 0 01-.5-1.151 4.482 4.482 0 01-.188-1.273c.063-.424.125-2.485 1.313-3.455a.714.714 0 01.937.182.539.539 0 010 .728 5.047 5.047 0 00-.125 5.272c.405.567.866 1.095 1.375 1.576z"
                                    fillOpacity=".5"
                                  ></path>
                                  <path d="M20.694 20.006a5.473 5.473 0 00-1.313-3.151 10.816 10.816 0 00-2.625-1.94 14.466 14.466 0 01-2.375-1.642 4.032 4.032 0 01-1.187-2.121 4.243 4.243 0 01.306-2.48 6.692 6.692 0 011.756-2.242.26.26 0 00.063-.303.242.242 0 00-.375-.06 5.577 5.577 0 00-2.438 2.06 5.01 5.01 0 00-.881 3.273 5.25 5.25 0 00.438 1.697 6 6 0 001 1.455 10.78 10.78 0 002.687 1.878c.848.44 1.645.969 2.375 1.576a3.603 3.603 0 011.188 2.182c.176.879.066 1.79-.313 2.606a6.13 6.13 0 01-1.813 2.182.207.207 0 00-.063.303c.035.064.1.11.173.121s.149-.01.203-.06a7.801 7.801 0 002.375-2.122c.62-.958.906-2.084.819-3.212z"></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      name="token"
                      defaultValue={"0.00"}
                      placeholder="Chainvoid Equivalent"
                      className="br cr jc oe re af if zg sg ii _i ej lj pk rk el ql"
                    />
                  </div>

                  <div className="ta">
                    <button className="zq qb jc be de oe of rg jh ii qi zi rj sj tj jk">
                      Buy Chainvoid
                    </button>
                  </div>
                </form>

                <div className="buy-conf-wrapper">
                  <p>Minimum amount to purchase</p>
                  <p>
                    <b>100 CHAINVOID</b>
                  </p>

                  <p>Total amount of BNB raised</p>
                  <p>
                    <b>100 BNB</b>
                  </p>

                  <p>Total amount of CHAINVOID sold</p>
                  <p>
                    <b>100 CHAINVOID</b>
                  </p>

                  <p>Total CHAINVOID in your wallet</p>
                  <p>
                    <b>100 CHAINVOID</b>
                  </p>

                  <p>Presale status</p>
                  <p>
                    <b>Active</b>
                  </p>

                  <p>Presale end date</p>
                  <p>
                    <b>10-feb, 2025</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d f l ea yb jc gj"
          style={{
            backgroundImage:
              "inear-gradient(180deg, #3e7dff 0%, rgba(62, 125, 255, 0) 100% )",
          }}
        ></div>
        <img
          src="/assets/images/shapes/hero-shape-1.svg"
          alt="any"
          className="d l f ea"
        />
        <img
          src="/assets/images/shapes/hero-shape-2.svg"
          alt="any"
          className="d m f ea"
        />
      </section>

      <Footer />
    </>
  );
}
