import { faqContents } from "../../constants/defaults";
import FaqItem from "./FaqItem";

export default function Faq() {
  return (
    <section
      id="faq"
      x-data="{openFaq1: false, openFaq2: false,  openFaq3: false, openFaq4: false, openFaq5: false, openFaq: false, }  "
      className="e ca nf ih ml"
    >
      <div className="a">
        <div className="wow fadeInUp la fb ld fi qn" data-wow-delay="0s">
          <span className="va gi pi ri bj fn"> FAQ </span>
          <h2 className="va li pi ui yi vl bo">Frequently Asked Questions</h2>
          <p className="la id gi qi xi ul">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
            congue arcu, In et dignissim quam condimentum vel.
          </p>
        </div>
        <div className="ha qb _d de">
          <div className="jc ng _o/12 jq/12">
            {faqContents.map((faq, index) => (
              <FaqItem
                key={`faq-${index + 1}`}
                title={faq.title}
                content={faq.content}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="d l z ea">
        <img src="/assets/images/shapes/faq-shape-1.svg" alt="shape" />
      </div>
      <div className="d m _ ea">
        <img src="/assets/images/shapes/faq-shape-2.svg" alt="shape" />
      </div>
    </section>
  );
}
