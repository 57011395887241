import { useEffect, useState } from "react";
import Faq from "../components/faq/Faq";
import useCountdown from "../hooks/useCountdown";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import { dateFormatter } from "../utils/functions";

export default function Home() {
  const { seconds, days, minutes, hours, hasEnded } = useCountdown(
    new Date("2024-05-30")
  );
  const [blogData, setBlogData] = useState<any[]>([]);
  const getBlogData = async () => {
    var requestOptions = {
      method: "GET",
    };

    var params = {
      api_token: "LVuBxxrObKRqdZHvu6DRLM06TA3cSHr6mUBnW2XB",
      categories: "",
      search: "AI",
      limit: "3",
    };

    var esc = encodeURIComponent;
    var query = Object.keys(params)
      .map(function (k) {
        return esc(k) + "=" + esc(params[k as keyof typeof params]);
      })
      .join("&");

    fetch("https://api.thenewsapi.com/v1/news/all?" + query, requestOptions)
      .then((response) => response.json())
      .then((result) => setBlogData(result.data))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getBlogData();
  }, []);
  return (
    <>
      <Navbar />
      <section id="home" className="e ca wh xh">
        <div className="a">
          <div className="ha qb _d">
            <div className="jc ng">
              <div className="la fd fi">
                <h1 className="pa li pi ui yi vl bo">Future Of AI Coin</h1>
                <p className="la pa gd gi qi xi vl">
                  Chain Void is a blockchain based project.
                </p>
                <div className="ma ra qb _d be de jm">
                  <div className="countdown-container">
                    <h2>Presale Starts In:</h2>
                    <div className="countdown">
                      <div className="countdown-item">
                        <span className="countdown-value">{days}</span>
                        <span className="countdown-label">Days</span>
                      </div>
                      <div className="countdown-item">
                        <span className="countdown-value">{hours}</span>
                        <span className="countdown-label">Hours</span>
                      </div>
                      <div className="countdown-item">
                        <span className="countdown-value">{minutes}</span>
                        <span className="countdown-label">Minutes</span>
                      </div>
                      <div className="countdown-item">
                        <span className="countdown-value">{seconds}</span>
                        <span className="countdown-label">Seconds</span>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0)"
                  className="oe of zg wg ii oi zi hk dark:hover:bg-opacity-90"
                  style={{ display: hasEnded ? "block" : "none" }}
                >
                  Presale
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d f l ea yb jc gj"
          style={{
            backgroundImage:
              "inear-gradient(180deg, #3e7dff 0%, rgba(62, 125, 255, 0) 100% )",
          }}
        ></div>
        <img
          src="/assets/images/shapes/hero-shape-1.svg"
          alt="logo"
          className="d l f ea"
        />
        <img
          src="/assets/images/shapes/hero-shape-2.svg"
          alt="logo"
          className="d m f ea"
        />
      </section>

      <section>
        <div className="a">
          <div className="wow fadeInUp ue bf yh al" data-wow-delay="0s">
            <h2 className="ra fi gi pi yi vl en">
              Join the 20,000+ companies using the our platform
            </h2>
            <div className="ha qb _d be de">
              <div className="ng">
                <a
                  href="https://uideck.com/"
                  target="_blank"
                  rel="nofollow noopenner"
                  className="ra qb hd de hj nj nk ok gm"
                >
                  <img
                    src="/assets/images/brands/uideck-white.svg"
                    alt="uideck"
                    className="la sb ac fi wk"
                  />
                  <img
                    src="/assets/images/brands/uideck.svg"
                    alt="uideck"
                    className="la ac fi xk"
                  />
                </a>
              </div>
              <div className="ng">
                <a
                  href="https://tailgrids.com/"
                  target="_blank"
                  rel="nofollow noopenner"
                  className="ra qb hd de hj nj nk ok gm"
                >
                  <img
                    src="/assets/images/brands/TailGrids-white.svg"
                    alt="tailgrids"
                    className="la sb ac fi wk"
                  />
                  <img
                    src="/assets/images/brands/tailgrids.svg"
                    alt="tailgrids"
                    className="la ac fi xk"
                  />
                </a>
              </div>
              <div className="ng">
                <a
                  href="https://lineicons.com/"
                  target="_blank"
                  rel="nofollow noopenner"
                  className="ra qb hd de hj nj nk ok gm"
                >
                  <img
                    src="/assets/images/brands/LineIcons-white.svg"
                    alt="lineicons"
                    className="la sb ac fi wk"
                  />
                  <img
                    src="/assets/images/brands/lineicons.svg"
                    alt="lineicons"
                    className="la ac fi xk"
                  />
                </a>
              </div>
              <div className="ng">
                <a
                  href="https://ayroui.com/"
                  target="_blank"
                  rel="nofollow noopenner"
                  className="ra qb hd de hj nj nk ok gm"
                >
                  <img
                    src="/assets/images/brands/AyroUI-white.svg"
                    alt="ayroui"
                    className="la sb ac fi wk"
                  />
                  <img
                    src="/assets/images/brands/ayroui.svg"
                    alt="ayroui"
                    className="la ac fi xk"
                  />
                </a>
              </div>
              <div className="ng">
                <a
                  href="https://plainadmin.com/"
                  target="_blank"
                  rel="nofollow noopenner"
                  className="ra qb hd de hj nj nk ok gm"
                >
                  <img
                    src="/assets/images/brands/PlainAdmin-white.svg"
                    alt="plainadmin"
                    className="la sb ac fi wk"
                  />
                  <img
                    src="/assets/images/brands/plainadmin.svg"
                    alt="plainadmin"
                    className="la ac fi xk"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="th zh">
        <div className="a">
          <div className="wow fadeInUp la fb id fi qn" data-wow-delay="0s">
            <span className="va gi pi ri bj fn"> Chain Void FEATURE </span>
            <h2 className="va li pi ui yi vl bo">Best Features</h2>
            <p className="gi qi xi ul">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
              congue arcu, In et dignissim quam condimentum vel.
            </p>
          </div>
          <div className="ha qb _d">
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 1.66675L5 8.33341V18.3334C5 27.5834 11.4 36.2334 20 38.3334C28.6 36.2334 35 27.5834 35 18.3334V8.33341L20 1.66675ZM20 19.9834H31.6667C30.7833 26.8501 26.2 32.9667 20 34.8834V20.0001H8.33333V10.5001L20 5.31675V19.9834Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Safe & Secure</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33.3333 9.99992H29.6999C29.8833 9.48325 29.9999 8.91658 29.9999 8.33325C29.9999 5.56659 27.7666 3.33325 24.9999 3.33325C23.2499 3.33325 21.7333 4.23325 20.8333 5.58325L19.9999 6.69992L19.1666 5.56659C18.2666 4.23325 16.7499 3.33325 14.9999 3.33325C12.2333 3.33325 9.99992 5.56659 9.99992 8.33325C9.99992 8.91658 10.1166 9.48325 10.2999 9.99992H6.66659C4.81659 9.99992 3.34992 11.4833 3.34992 13.3333L3.33325 31.6666C3.33325 33.5166 4.81659 34.9999 6.66659 34.9999H33.3333C35.1833 34.9999 36.6666 33.5166 36.6666 31.6666V13.3333C36.6666 11.4833 35.1833 9.99992 33.3333 9.99992ZM24.9999 6.66659C25.9166 6.66659 26.6666 7.41658 26.6666 8.33325C26.6666 9.24992 25.9166 9.99992 24.9999 9.99992C24.0833 9.99992 23.3333 9.24992 23.3333 8.33325C23.3333 7.41658 24.0833 6.66659 24.9999 6.66659ZM14.9999 6.66659C15.9166 6.66659 16.6666 7.41658 16.6666 8.33325C16.6666 9.24992 15.9166 9.99992 14.9999 9.99992C14.0833 9.99992 13.3333 9.24992 13.3333 8.33325C13.3333 7.41658 14.0833 6.66659 14.9999 6.66659ZM33.3333 31.6666H6.66659V28.3333H33.3333V31.6666ZM33.3333 23.3333H6.66659V13.3333H15.1333L11.6666 18.0499L14.3666 19.9999L18.3333 14.5999L19.9999 12.3333L21.6666 14.5999L25.6333 19.9999L28.3333 18.0499L24.8666 13.3333H33.3333V23.3333Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Early Bonus</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 3.33325L10.8334 18.3333H29.1667L20 3.33325Z"
                      fill="white"
                    />
                    <path
                      d="M29.1666 36.6667C33.3088 36.6667 36.6666 33.3089 36.6666 29.1667C36.6666 25.0246 33.3088 21.6667 29.1666 21.6667C25.0245 21.6667 21.6666 25.0246 21.6666 29.1667C21.6666 33.3089 25.0245 36.6667 29.1666 36.6667Z"
                      fill="white"
                    />
                    <path d="M5 22.5H18.3333V35.8333H5V22.5Z" fill="white" />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Universal Access</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 24.9999H18.3333V21.6666H5V24.9999ZM5 31.6666H18.3333V28.3333H5V31.6666ZM5 18.3333H18.3333V14.9999H5V18.3333ZM5 8.33325V11.6666H18.3333V8.33325H5ZM21.6667 8.33325H35V31.6666H21.6667V8.33325Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Secure Storage</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35 30V31.6667C35 33.5 33.5 35 31.6667 35H8.33333C6.48333 35 5 33.5 5 31.6667V8.33333C5 6.5 6.48333 5 8.33333 5H31.6667C33.5 5 35 6.5 35 8.33333V10H20C18.15 10 16.6667 11.5 16.6667 13.3333V26.6667C16.6667 28.5 18.15 30 20 30H35ZM20 26.6667H36.6667V13.3333H20V26.6667ZM26.6667 22.5C25.2833 22.5 24.1667 21.3833 24.1667 20C24.1667 18.6167 25.2833 17.5 26.6667 17.5C28.05 17.5 29.1667 18.6167 29.1667 20C29.1667 21.3833 28.05 22.5 26.6667 22.5Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Low Cost</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2 yo/3">
              <div className="wow fadeInUp la bb jd fi" data-wow-delay="0s">
                <div className="la gb qb cc mc be de oe of zi">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 13.3333C16.3167 13.3333 13.3333 16.3167 13.3333 20C13.3333 23.6833 16.3167 26.6667 20 26.6667C23.6833 26.6667 26.6667 23.6833 26.6667 20C26.6667 16.3167 23.6833 13.3333 20 13.3333ZM8.33333 25H5V31.6667C5 33.5 6.5 35 8.33333 35H15V31.6667H8.33333V25ZM8.33333 8.33333H15V5H8.33333C6.5 5 5 6.5 5 8.33333V15H8.33333V8.33333ZM31.6667 5H25V8.33333H31.6667V15H35V8.33333C35 6.5 33.5 5 31.6667 5ZM31.6667 31.6667H25V35H31.6667C33.5 35 35 33.5 35 31.6667V25H31.6667V31.6667Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="va ji pi yi vl gn rp rq">Several Profit</h3>
                  <p className="ii qi xi ul dn qp pq">
                    Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod
                    tempor labore labore labore et dolor .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="e ca">
        <div className="a">
          <div className="me nf fh wg ml ym zm lp nq">
            <div className="ha qb _d be">
              <div className="jc ng yo/2">
                <div
                  className="wow fadeInUp hb qb be de no"
                  data-wow-delay="0s"
                >
                  <div id="chart"></div>
                </div>
              </div>
              <div className="jc ng yo/2">
                <div className="wow fadeInUp ib" data-wow-delay="0s">
                  <span className="va gi pi ri bj fn"> TOKEN </span>
                  <h2 className="va li pi ui yi vl bo">Token Sale</h2>
                  <p className="gi qi xi ul">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus condimentum tellus at lectus pulvinar, id auctor
                    felis iaculis. In vestibulum neque sem, at dapibus justo
                    facilisis in.
                  </p>
                </div>
                <div className="wow fadeInUp ie" data-wow-delay="0s">
                  <p className="qb">
                    <span className="oa dc rc oe of"></span>
                    <span className="gi qi xi ul">
                      {" "}
                      73% Financial Overhead{" "}
                    </span>
                  </p>
                  <p className="qb">
                    <span className="oa dc rc oe sf"></span>
                    <span className="gi qi xi ul"> 55% Bonus & found </span>
                  </p>
                  <p className="qb">
                    <span className="oa dc rc oe tf"></span>
                    <span className="gi qi xi ul"> 38% it infastrueture </span>
                  </p>
                  <p className="qb">
                    <span className="oa dc rc oe uf"></span>
                    <span className="gi qi xi ul">
                      {" "}
                      20.93% Gift Code Inventory{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d m v ea">
          <img src="/assets/images/shapes/token-sale-shape.svg" alt="shape" />
        </div>
      </section>

      <section id="roadmap" className="e ca gh">
        <div className="a">
          <div className="wow fadeInUp la fb id fi qn" data-wow-delay="0s">
            <span className="va gi pi ri bj fn"> ROADMAP </span>
            <h2 className="va li pi ui yi vl bo">The Timeline</h2>
            <p className="gi qi xi ul">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
              congue arcu, In et dignissim quam condimentum vel.
            </p>
          </div>
          <div className="ha qb de">
            <div className="jc ng zo/12 iq/12">
              <div
                className="wow fadeInUp e ha qb _d yn mp"
                data-wow-delay="0s"
              >
                <span className="d f w sb yb sc nf nl ln/2 un"></span>
                <div className="jc ng vn/2">
                  <div className="e ca ra me nf hh sg gl rn sn ao oo">
                    <span className="d h/2 l sb ec tc qd/2 oe te df kf bl mn nn un io"></span>
                    <span className="d x h/2 sb bc qc qd/2 sd nf gl un"></span>
                    <h3 className="va ji pi yi vl">Feb 25,2020</h3>
                    <p className="za ii qi xi ul">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc sed congue arcu, In et dignissim quam condimentum vel
                    </p>
                    <a href="#" className="rb be de oe kf ig _h">
                      <span className="uh">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.7287 11.1771C16.5267 15.9986 11.6433 18.9329 6.82128 17.7306C2.00125 16.5285 -0.933006 11.6449 0.269537 6.82378C1.47102 2.00177 6.3544 -0.932704 11.175 0.269311C15.9966 1.47133 18.9307 6.35553 17.7286 11.1772L17.7287 11.1771H17.7287Z"
                            fill="#F7931A"
                          />
                          <path
                            d="M13.1113 8.32768C13.2804 7.19652 12.4193 6.58848 11.2416 6.18285L11.6237 4.65059L10.6909 4.41817L10.319 5.91009C10.0738 5.84893 9.82201 5.79131 9.57173 5.73418L9.94632 4.23242L9.01417 4L8.63193 5.53176C8.42902 5.48556 8.22972 5.4399 8.03636 5.39179L8.03744 5.38697L6.75117 5.06577L6.50305 6.06199C6.50305 6.06199 7.19507 6.22062 7.18048 6.23038C7.55819 6.32465 7.62649 6.57468 7.61515 6.77286L7.17998 8.51847C7.20599 8.52507 7.23973 8.53463 7.27695 8.54959C7.24584 8.54186 7.21272 8.53343 7.17836 8.5252L6.56841 10.9705C6.52225 11.0853 6.40509 11.2575 6.14101 11.1921C6.15036 11.2057 5.46309 11.0229 5.46309 11.0229L5 12.0906L6.2138 12.3932C6.43961 12.4498 6.66089 12.5091 6.87881 12.5648L6.49283 14.1146L7.42449 14.347L7.80672 12.8137C8.06124 12.8828 8.30824 12.9465 8.55005 13.0066L8.1691 14.5327L9.10188 14.7651L9.48781 13.2182C11.0783 13.5192 12.2743 13.3979 12.7776 11.9592C13.1833 10.801 12.7574 10.1329 11.9207 9.69725C12.5301 9.55669 12.9892 9.15584 13.1116 8.32781L13.1113 8.3276L13.1113 8.32768ZM10.9802 11.316C10.6919 12.4743 8.74179 11.8481 8.10952 11.6911L8.62171 9.63788C9.25394 9.79571 11.2814 10.1081 10.9802 11.316H10.9802ZM11.2686 8.3109C11.0057 9.36445 9.38257 8.8292 8.85604 8.69795L9.32041 6.83576C9.84694 6.96701 11.5426 7.21197 11.2687 8.3109H11.2686Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span className="rh ki oi xi ul"> Bitcoin </span>
                      <span className="ni oi xi wl"> 4.5% </span>
                      <span>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                            fill="#60D16B"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="jc ng vn/2"></div>
                <div className="jc ng vn/2"></div>
                <div className="jc ng vn/2">
                  <div className="e ca ra me nf hh sg gl tn sn po">
                    <span className="d h/2 l sb ec tc qd/2 oe te df kf bl on pn un jo"></span>
                    <span className="d y h/2 sb bc qc qd/2 sd nf gl un"></span>
                    <h3 className="va ji pi yi vl">Jan 14,2021</h3>
                    <p className="za ii qi xi ul">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc sed congue arcu, In et dignissim quam condimentum vel
                    </p>
                    <a href="#" className="rb be de oe kf ig _h">
                      <span className="uh">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.7307 11.1771C16.5286 15.9986 11.6446 18.9329 6.82205 17.7306C2.00148 16.5285 -0.933111 11.6449 0.269567 6.82378C1.47119 2.00177 6.35511 -0.932704 11.1762 0.269311C15.9985 1.47133 18.9329 6.35553 17.7306 11.1772L17.7307 11.1771H17.7307Z"
                            fill="#1181E7"
                          />
                          <g clipPath="url(#clip0_27_564)">
                            <path
                              d="M8.99897 4L8.93188 4.22789V10.84L8.99897 10.907L12.0682 9.09273L8.99897 4Z"
                              fill="#D6D6D6"
                            />
                            <path
                              d="M8.99899 4L5.92969 9.09273L8.99899 10.907V7.69762V4Z"
                              fill="white"
                            />
                            <path
                              d="M8.99899 11.4881L8.96118 11.5342V13.8896L8.99899 13.9999L12.0701 9.6748L8.99899 11.4881Z"
                              fill="#D6D6D6"
                            />
                            <path
                              d="M8.99899 13.9999V11.4881L5.92969 9.6748L8.99899 13.9999Z"
                              fill="white"
                            />
                            <path
                              d="M8.99902 10.907L12.0682 9.09274L8.99902 7.69763V10.907Z"
                              fill="#F3F3F3"
                            />
                            <path
                              d="M5.92969 9.09274L8.99899 10.907V7.69763L5.92969 9.09274Z"
                              fill="#E2E2E2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_27_564">
                              <rect
                                width="10"
                                height="10"
                                fill="white"
                                transform="translate(4 4)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="rh ki oi xi ul"> ethereum </span>
                      <span className="ni oi xi wl"> 2.5% </span>
                      <span>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                            fill="#60D16B"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="jc ng vn/2">
                  <div className="e ca ra me nf hh sg gl rn sn ao oo">
                    <span className="d h/2 l sb ec tc qd/2 oe te df kf bl mn nn un io"></span>
                    <span className="d x h/2 sb bc qc qd/2 sd nf gl un"></span>
                    <h3 className="va ji pi yi vl">Mar 30,2021</h3>
                    <p className="za ii qi xi ul">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc sed congue arcu, In et dignissim quam condimentum vel
                    </p>
                    <a href="#" className="rb be de oe kf ig _h">
                      <span className="uh">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.7287 11.1771C16.5267 15.9986 11.6433 18.9329 6.82128 17.7306C2.00125 16.5285 -0.933006 11.6449 0.269537 6.82378C1.47102 2.00177 6.3544 -0.932704 11.175 0.269311C15.9966 1.47133 18.9307 6.35553 17.7286 11.1772L17.7287 11.1771H17.7287Z"
                            fill="#295ADA"
                          />
                          <path
                            d="M9 4L8.15344 4.47477L5.84656 5.77523L5 6.25V10.75L5.84656 11.2248L8.1746 12.5252L9.02116 13L9.86772 12.5252L12.1534 11.2248L13 10.75V6.25L12.1534 5.77523L9.84656 4.47477L9 4ZM6.69312 9.80046V7.19954L9 5.89908L11.3069 7.19954V9.80046L9 11.1009L6.69312 9.80046Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span className="rh ki oi xi ul"> chainlink </span>
                      <span className="ni oi xi wl"> 4.5% </span>
                      <span>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                            fill="#60D16B"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="jc ng vn/2"></div>
                <div className="jc ng vn/2"></div>
                <div className="jc ng vn/2">
                  <div className="e ca ra me nf hh sg gl tn sn po">
                    <span className="d h/2 l sb ec tc qd/2 oe te df kf bl on pn un jo"></span>
                    <span className="d y h/2 sb bc qc qd/2 sd nf gl un"></span>
                    <h3 className="va ji pi yi vl">Dec 19,2021</h3>
                    <p className="za ii qi xi ul">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc sed congue arcu, In et dignissim quam condimentum vel
                    </p>
                    <a href="#" className="rb be de oe kf ig _h">
                      <span className="uh">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.7307 11.1771C16.5286 15.9986 11.6446 18.9329 6.82205 17.7306C2.00148 16.5285 -0.933111 11.6449 0.269567 6.82378C1.47119 2.00177 6.35511 -0.932704 11.1762 0.269311C15.9985 1.47133 18.9329 6.35553 17.7306 11.1772L17.7307 11.1771H17.7307Z"
                            fill="#8247E5"
                          />
                          <path
                            d="M11.5718 7.75074C11.389 7.64392 11.154 7.64392 10.9452 7.75074L9.48303 8.63205L8.49086 9.19288L7.05483 10.0742C6.87206 10.181 6.63708 10.181 6.4282 10.0742L5.30548 9.37982C5.12272 9.273 4.99217 9.05935 4.99217 8.81899V7.48368C4.99217 7.27003 5.09661 7.05638 5.30548 6.92285L6.4282 6.25519C6.61097 6.14837 6.84595 6.14837 7.05483 6.25519L8.17755 6.94956C8.36031 7.05638 8.49086 7.27003 8.49086 7.51039V8.39169L9.48303 7.80415V6.89614C9.48303 6.68249 9.37859 6.46884 9.16971 6.33531L7.08094 5.08012C6.89817 4.97329 6.66319 4.97329 6.45431 5.08012L4.31332 6.36202C4.10444 6.46884 4 6.68249 4 6.89614V9.40653C4 9.62018 4.10444 9.83383 4.31332 9.96736L6.4282 11.2226C6.61097 11.3294 6.84595 11.3294 7.05483 11.2226L8.49086 10.368L9.48303 9.78042L10.9191 8.92582C11.1018 8.81899 11.3368 8.81899 11.5457 8.92582L12.6684 9.59347C12.8512 9.7003 12.9817 9.91395 12.9817 10.1543V11.4896C12.9817 11.7033 12.8773 11.9169 12.6684 12.0504L11.5718 12.7181C11.389 12.8249 11.154 12.8249 10.9452 12.7181L9.82245 12.0504C9.63969 11.9436 9.50914 11.73 9.50914 11.4896V10.635L8.51697 11.2226V12.1039C8.51697 12.3175 8.62141 12.5312 8.83029 12.6647L10.9452 13.9199C11.1279 14.0267 11.3629 14.0267 11.5718 13.9199L13.6867 12.6647C13.8695 12.5579 14 12.3442 14 12.1039V9.56677C14 9.35312 13.8956 9.13947 13.6867 9.00594L11.5718 7.75074Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span className="rh ki oi xi ul"> polygon </span>
                      <span className="ni oi xi wl"> 2.5% </span>
                      <span>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                            fill="#60D16B"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d l n ea">
          <img src="/assets/images/shapes/timeline.svg" alt="shape" />
        </div>
      </section>

      {/* <section id="testimonial" className="nf th oh ml">
        <div className="a">
          <div className="wow fadeInUp la fb id fi qn" data-wow-delay="0s">
            <span className="va gi pi ri bj fn"> TESTIMONIALS </span>
            <h2 className="va li pi ui yi vl bo">What Our Client Say's</h2>
            <p className="gi qi xi ul">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
              congue arcu, In et dignissim quam condimentum vel.
            </p>
          </div>
          <div className="ha qb _d">
            <div className="jc ng vn/2">
              <div
                className="wow fadeInUp e ca ra ke qe kf sg hh ol um xn mq"
                data-wow-delay="0s"
              >
                <div className="d m f da">
                  <img
                    src="/assets/images/shapes/testimonial-shape.svg"
                    alt="shape"
                  />
                </div>
                <div className="xa qb be">
                  <div className="sa cc jc kd ke ne">
                    <img
                      src="/assets/images/testimonials/image-01.jpg"
                      alt="author"
                    />
                  </div>
                  <div className="jc">
                    <h3 className="jb gi oi aj vl">Jason Keys</h3>
                    <p className="hi qi xi vl">CEO & Founder @ Dreampeet.</p>
                  </div>
                </div>
                <div>
                  <p className="ii qi ti xi ul">
                    “I believe in lifelong learning and Learn. is a great place
                    to learn from experts. I've learned a lot and recommend it
                    to all my friends and familys.”
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2">
              <div
                className="wow fadeInUp e ca ra ke qe kf sg hh ol um xn mq"
                data-wow-delay="0s"
              >
                <div className="d m f da">
                  <img
                    src="/assets/images/shapes/testimonial-shape.svg"
                    alt="shape"
                  />
                </div>
                <div className="xa qb be">
                  <div className="sa cc jc kd ke ne">
                    <img
                      src="/assets/images/testimonials/image-02.jpg"
                      alt="author"
                    />
                  </div>
                  <div className="jc">
                    <h3 className="jb gi oi aj vl">Mariya Merry</h3>
                    <p className="hi qi xi vl">CEO & Founder @ Betex.</p>
                  </div>
                </div>
                <div>
                  <p className="ii qi ti xi ul">
                    “I believe in lifelong learning and Learn. is a great place
                    to learn from experts. I've learned a lot and recommend it
                    to all my friends and familys.”
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2">
              <div
                className="wow fadeInUp e ca ra ke qe kf sg hh ol um xn mq"
                data-wow-delay="0s"
              >
                <div className="d m f da">
                  <img
                    src="/assets/images/shapes/testimonial-shape.svg"
                    alt="shape"
                  />
                </div>
                <div className="xa qb be">
                  <div className="sa cc jc kd ke ne">
                    <img
                      src="/assets/images/testimonials/image-03.jpg"
                      alt="author"
                    />
                  </div>
                  <div className="jc">
                    <h3 className="jb gi oi aj vl">Andria Jolly</h3>
                    <p className="hi qi xi vl">CEO & Founder @ Chain VoidX.</p>
                  </div>
                </div>
                <div>
                  <p className="ii qi ti xi ul">
                    “I believe in lifelong learning and Learn. is a great place
                    to learn from experts. I've learned a lot and recommend it
                    to all my friends and familys.”
                  </p>
                </div>
              </div>
            </div>
            <div className="jc ng vn/2">
              <div
                className="wow fadeInUp e ca ra ke qe kf sg hh ol um xn mq"
                data-wow-delay="0s"
              >
                <div className="d m f da">
                  <img
                    src="/assets/images/shapes/testimonial-shape.svg"
                    alt="shape"
                  />
                </div>
                <div className="xa qb be">
                  <div className="sa cc jc kd ke ne">
                    <img
                      src="/assets/images/testimonials/image-04.jpg"
                      alt="author"
                    />
                  </div>
                  <div className="jc">
                    <h3 className="jb gi oi aj vl">Devid Willium</h3>
                    <p className="hi qi xi vl">CEO & Founder @ Coinbase.</p>
                  </div>
                </div>
                <div>
                  <p className="ii qi ti xi ul">
                    “I believe in lifelong learning and Learn. is a great place
                    to learn from experts. I've learned a lot and recommend it
                    to all my friends and familys.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* 
      <section id="download" className="ih">
        <div className="a">
          <div className="ha qb _d be">
            <div className="jc ng yo/2">
              <div className="wow fadeInUp hb cd no" data-wow-delay="0s">
                <span className="va gi pi ri bj fn"> Download Our App </span>
                <h2 className="va li pi ui yi vl bo">
                  The choice is yours, we've got you covered
                </h2>
                <p className="ra gi qi xi ul">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  tristique, nisl ut viverra porttitor, dolor sem lacinia orci,
                  et pretium quam mi a eros sed molestie est.
                </p>
                <div className="na qb be">
                  <div className="pg">
                    <a
                      href="javascript:void(0)"
                      className="qb be de oe re ef vf kg ai ii oi zi hk dm"
                    >
                      <span className="wa qb ac pc be de oe of zi">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7101 19.5C17.8801 20.74 17.0001 21.95 15.6601 21.97C14.3201 22 13.8901 21.18 12.3701 21.18C10.8401 21.18 10.3701 21.95 9.10009 22C7.79009 22.05 6.80009 20.68 5.96009 19.47C4.25009 17 2.94009 12.45 4.70009 9.39C5.57009 7.87 7.13009 6.91 8.82009 6.88C10.1001 6.86 11.3201 7.75 12.1101 7.75C12.8901 7.75 14.3701 6.68 15.9201 6.84C16.5701 6.87 18.3901 7.1 19.5601 8.82C19.4701 8.88 17.3901 10.1 17.4101 12.63C17.4401 15.65 20.0601 16.66 20.0901 16.67C20.0601 16.74 19.6701 18.11 18.7101 19.5ZM13.0001 3.5C13.7301 2.67 14.9401 2.04 15.9401 2C16.0701 3.17 15.6001 4.35 14.9001 5.19C14.2101 6.04 13.0701 6.7 11.9501 6.61C11.8001 5.46 12.3601 4.26 13.0001 3.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      App Store
                    </a>
                  </div>
                  <div className="pg">
                    <a
                      href="javascript:void(0)"
                      className="qb be de oe re ef lf kg ai ii oi xi gk vl cm"
                    >
                      <span className="wa qb ac pc be de oe qf zi il xl">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="cg"
                        >
                          <path d="M3 20.5V3.50002C3 2.91002 3.34 2.39002 3.84 2.15002L13.69 12L3.84 21.85C3.34 21.6 3 21.09 3 20.5ZM16.81 15.12L6.05 21.34L14.54 12.85L16.81 15.12ZM20.16 10.81C20.5 11.08 20.75 11.5 20.75 12C20.75 12.5 20.53 12.9 20.18 13.18L17.89 14.5L15.39 12L17.89 9.50002L20.16 10.81ZM6.05 2.66002L16.81 8.88002L14.54 11.15L6.05 2.66002Z" />
                        </svg>
                      </span>
                      Play Store
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="jc ng yo/2">
              <div className="wow fadeInUp e -ud-z-10 fi" data-wow-delay="0s">
                <img
                  src="/assets/images/download/app-image.png"
                  alt="app image"
                  className="la sb wk fi"
                />
                <img
                  src="/assets/images/download/app-image-2.png"
                  alt="app image"
                  className="la xk"
                />
                <span
                  className="d m n uc fc oe ea"
                  style={{
                    background:
                      "linear-gradient(180deg, #3736F9 0%, #FF60A6 100%)",
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Faq />

      <section id="blog" className="th oh">
        <div className="a">
          <div className="wow fadeInUp la fb id fi qn" data-wow-delay="0s">
            <span className="va gi pi ri bj fn"> LATEST NEWS </span>
            <h2 className="va li pi ui yi vl bo">Recent News & Blogs</h2>
            <p className="gi qi xi ul">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
              congue arcu, In et dignissim quam condimentum vel.
            </p>
          </div>
          <div className="ha qb _d">
            {blogData.map((blog, index) => (
              <div key={`blog-${index + 1}`} className="jc ng vn/2 yo/3">
                <div
                  className="wow fadeInUp ra ke me nf ml"
                  data-wow-delay="0s"
                >
                  <a
                    href={blog?.url}
                    target="_blank"
                    rel={"noreferrer"}
                    className="ob"
                  >
                    <img
                      src={blog?.image_url}
                      alt="blog-image"
                      className="jc blog-image"
                    />
                  </a>
                  <div className="hg kp lq">
                    <div className="ya qb be">
                      <p className="wa qb be xi ul">
                        <span className="rh aj vl">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            className="cg"
                          >
                            <path d="M11.0001 3.66675C11.9725 3.66675 12.9052 4.05306 13.5928 4.74069C14.2804 5.42832 14.6667 6.36095 14.6667 7.33341C14.6667 8.30587 14.2804 9.2385 13.5928 9.92614C12.9052 10.6138 11.9725 11.0001 11.0001 11.0001C10.0276 11.0001 9.09499 10.6138 8.40736 9.92614C7.71972 9.2385 7.33341 8.30587 7.33341 7.33341C7.33341 6.36095 7.71972 5.42832 8.40736 4.74069C9.09499 4.05306 10.0276 3.66675 11.0001 3.66675ZM11.0001 12.8334C15.0517 12.8334 18.3334 14.4742 18.3334 16.5001V18.3334H3.66675V16.5001C3.66675 14.4742 6.94841 12.8334 11.0001 12.8334Z" />
                          </svg>
                        </span>
                        {blog?.sourse}
                      </p>
                      <p className="qb be xi ul">
                        <span className="rh aj vl">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            className="cg"
                          >
                            <path d="M6.125 10.5H7.875V12.25H6.125V10.5ZM18.375 5.25V17.5C18.375 17.9641 18.1906 18.4092 17.8624 18.7374C17.5342 19.0656 17.0891 19.25 16.625 19.25H4.375C3.40375 19.25 2.625 18.4625 2.625 17.5V5.25C2.625 4.78587 2.80937 4.34075 3.13756 4.01256C3.46575 3.68437 3.91087 3.5 4.375 3.5H5.25V1.75H7V3.5H14V1.75H15.75V3.5H16.625C17.0891 3.5 17.5342 3.68437 17.8624 4.01256C18.1906 4.34075 18.375 4.78587 18.375 5.25ZM4.375 7H16.625V5.25H4.375V7ZM16.625 17.5V8.75H4.375V17.5H16.625ZM13.125 12.25V10.5H14.875V12.25H13.125ZM9.625 12.25V10.5H11.375V12.25H9.625ZM6.125 14H7.875V15.75H6.125V14ZM13.125 15.75V14H14.875V15.75H13.125ZM9.625 15.75V14H11.375V15.75H9.625Z" />
                          </svg>
                        </span>
                        {dateFormatter(blog?.published_at)}
                      </p>
                    </div>
                    <h3>
                      <a
                        href={blog?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="pa ob ji pi aj kk vl fm en sp qq"
                      >
                        {blog?.title}
                      </a>
                    </h3>
                    <p className="ii qi wi xi ul">{blog?.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="nf gh ml">
        <div className="a">
          <div className="ha qb _d be">
            <div className="jc ng ap/12">
              <div className="wow fadeInUp fb nd" data-wow-delay="0s">
                <span className="va gi pi ri bj fn"> Contact Us </span>
                <h2 className="va li pi ui yi vl bo">
                  Let's talk about your problem.
                </h2>
              </div>
              <div className="ha qb _d">
                <div className="jc ng qm/2">
                  <div className="wow fadeInUp lb _c" data-wow-delay="0s">
                    <h3 className="pa gi oi aj vl">Our Location</h3>
                    <p className="ii qi wi xi ul">
                      401 Broadway, 24th Floor, Orchard Cloud View, London
                    </p>
                  </div>
                </div>
                <div className="jc ng qm/2">
                  <div className="wow fadeInUp lb _c" data-wow-delay="0s">
                    <h3 className="pa gi oi aj vl">Email Address</h3>
                    <p className="ii qi wi xi ul">
                      <a
                        href="cdn-cgi/l/email-protection.html"
                        className="__cf_email__"
                        data-cfemail="98f1f6fef7d8e1f7edeafcf7f5f9f1f6b6fbf7f5"
                      >
                        [email&#160;protected]
                      </a>
                    </p>
                    <p className="ii qi wi xi ul">
                      <a
                        href="cdn-cgi/l/email-protection.html"
                        className="__cf_email__"
                        data-cfemail="f0939f9e84919384b0899f8582949f9d91999ede939f9d"
                      >
                        [email&#160;protected]
                      </a>
                    </p>
                  </div>
                </div>
                <div className="jc ng qm/2">
                  <div className="wow fadeInUp lb _c" data-wow-delay="0s">
                    <h3 className="pa gi oi aj vl">Phone Number</h3>
                    <p className="ii qi wi xi ul">+990 846 73644</p>
                    <p className="ii qi wi xi ul">+550 9475 4543</p>
                  </div>
                </div>
                <div className="jc ng qm/2">
                  <div className="wow fadeInUp lb _c" data-wow-delay="0s">
                    <h3 className="pa gi oi aj vl">How Can We Help?</h3>
                    <p className="ii qi wi xi ul">
                      Tell us your problem we will get back to you ASAP.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="jc ng bp/12">
              <div
                className="sm:ud-14 wow fadeInUp pe kf fh wg gl"
                data-wow-delay="0s"
              >
                <h3 className="xa mi pi aj vl hn tp sq">Send us a Message</h3>
                <form>
                  <div className="za">
                    <label htmlFor="name" className="ya ob hi qi aj vl">
                      Full Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Enter your full name"
                      className="jc pe re ff if zg _g ii qi _i lj pk dl il tl"
                    />
                  </div>
                  <div className="za">
                    <label htmlFor="email" className="ya ob hi qi aj vl">
                      Email Address*
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter your email address"
                      className="jc pe re ff if zg _g ii qi _i lj pk dl il tl"
                    />
                  </div>
                  <div className="za">
                    <label htmlFor="message" className="ya ob hi qi aj vl">
                      Message*
                    </label>
                    <textarea
                      rows={6}
                      id="message"
                      placeholder="Type your message"
                      className="jc pe re ff if zg _g ii qi _i lj pk dl il tl"
                    ></textarea>
                  </div>
                  <div>
                    <button className="jc oe of lg fi ii oi zi hk il yl dm">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section id="newsletter" className="e ca">
        <div className="d f l ea hc jc nf ml"></div>
        <div className="a">
          <div className="wow fadeInUp e ca ke ne qf gg tm" data-wow-delay="0s">
            <div className="ha qb _d be">
              <div className="jc ng yo/2">
                <div className="ra no">
                  <div className="cd">
                    <h2 className="ya li pi ui zi bo">Newsletter</h2>
                    <p className="gi qi zi">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam vitae quam nec ante aliquet fringilla vel at erat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="jc pg yo/2">
                <div>
                  <form className="e">
                    <input
                      type="email"
                      placeholder="Enter email address"
                      className="jc oe re _e kf xg bh ii qi xi lj ul an"
                    />
                    <button className="o h/2 ab rb _b be oe of ch ii qi zi hk im mm rm/2">
                      Submit
                      <span className="sh">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.67496 17.5L19.1666 10L1.67496 2.5L1.66663 8.33333L14.1666 10L1.66663 11.6667L1.67496 17.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="d f m ea">
              <img
                src="/assets/images/shapes/newsletter-shape.svg"
                alt="shape"
              />
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
}
