import { Outlet, Navigate } from "react-router-dom";

export const ProtectedRoute = () => {
  const canView = true;
  if (!canView) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
