import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";

export default function Privacy() {
  return (
    <>
      <Navbar />

      <Footer />
    </>
  );
}
