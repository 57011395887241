import Home from "../pages/index";
import Presale from "../pages/presale";
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";

export const routes = {
  home: "/",
  terms: "/terms-and-condition",
  privacy: "/privacy-policy",
  presale: "/presale",
};

export const protectedRoute = [
  {
    path: routes.presale,
    component: Presale,
  },
];
export const unProtectedRoutes = [
  {
    path: routes.home,
    component: Home,
  },
  {
    path: routes.terms,
    component: Terms,
  },
  {
    path: routes.privacy,
    component: Privacy,
  },
];
