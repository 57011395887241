import moment from "moment";

const formatter = new Intl.NumberFormat();

/**
 * Format a number as a string using a number formatter.
 *
 * @param num - The number to format.
 * @returns The formatted number as a string.
 */
export const numberFormatter = (num: number): string => {
  // Use the formatter to format the number as a string
  return formatter.format(num);
};

/**
 * Returns a string representing the time elapsed since the provided date.
 *
 * @param {string} date - The date to calculate the elapsed time from.
 * @param {boolean} noPrefix - Whether to include the time prefix (e.g. "a few moments ago").
 * @returns {string} - The formatted string representing the elapsed time.
 */
export const momentsAgo = (date: string, noPrefix = false) => {
  // Convert the provided date string to a moment object in UTC timezone
  const momentDate = moment.utc(`${date}`);

  // Convert the moment object to the local timezone and set the seconds to 0
  const localDate = momentDate.local().startOf("seconds");

  // Calculate the time elapsed since the local date and format it
  const formattedDate = localDate.fromNow(noPrefix);

  return formattedDate;
};

/**
 * Formats a date string into a specific date-time format.
 * @param {string} date - The date string to be formatted.
 * @returns {string} - The formatted date-time string.
 */
export const dateTimeFormatter = (date: string): string => {
  // Parse the input date string into a Moment object, considering it as UTC time.
  const momentDate = moment.utc(date);

  // Convert the Moment object to the local time zone and format it using the desired format.
  const formattedDateTime = momentDate.local().format("DD MMM YYYY hh:mm A");

  // Return the formatted date-time string.
  return formattedDateTime;
};

/**
 * Checks if a given date has passed.
 *
 * @param {string} date - The date to be checked.
 * @returns {boolean} - Returns true if the date has passed, false otherwise.
 */
export const hasPassed = (date: string): boolean => {
  // Convert the date to UTC
  const utcDate = moment.utc(`${date}`);

  // Convert the UTC date to local timezone
  const localDate = utcDate.local();

  // Check if the local date is before the current time
  const isPassed = localDate.isBefore();

  // Return the result
  return isPassed;
};
/**
 * Format a date string to "DD MMM YYYY" format.
 *
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted date string.
 */
export const dateFormatter = (date: string): string => {
  // Convert the date string to UTC time
  const utcDate = moment.utc(date);

  // Convert the UTC time to local time and format it
  const formattedDate = utcDate.local().format("DD MMM YYYY");

  // Return the formatted date string
  return formattedDate;
};
/**
 * Returns the current time in the specified format.
 * @param date - The time to adjust. Format: "HH:MM"
 * @param prefix - The number of hours to add to the current time. Default: 0
 * @returns The adjusted time in the format "HH:MM AM/PM"
 */
export const realTime = (date: string, prefix = 0) => {
  return moment(
    new Date().setHours(parseInt(date.split(":")[0]) + prefix, 0)
  ).format("LT");
};
export const formatRealTime = (date: string) => {
  return `${realTime(date)} - ${realTime(date, 1)}`;
};
export const escapeHtml = (text: string) => {
  let map: any = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };

  return text.replace(/[&<>"']/g, function (m: string) {
    return map[m];
  });
};
export const capitalizeFirstLetter = (str: string) => {
  let item = str.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
    return letter.toUpperCase();
  });
  return item;
};

/**
 * Transforms an object into an array of select options.
 * @param param - The object to be transformed.
 * @returns An array of select options.
 */
export const toSelectOption = (param?: { [key: string]: string }) => {
  // check if param is defined
  if (!param) {
    return [{ value: "", label: "Select" }];
  }
  // Map over the entries of the object and transform them into select options
  return Object.entries(param).map(([key, value]) => {
    return { value: key, label: value };
  });
};
