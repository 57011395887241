import { useState } from "react";

export default function FaqItem({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  const [openFaq, setOpenFaq] = useState(false);

  return (
    <div
      className="single-faq wow fadeInUp ra me kf ah ch gl zn _n"
      data-wow-delay="0s"
    >
      <button
        onClick={() => setOpenFaq(!openFaq)}
        // @click="openFaq4 = !openFaq4"

        className="faq-btn qb jc be ee ei"
      >
        <h3 className="kb ii pi aj vl dn co">{title}</h3>
        <span
          className={`icon rb gc jc md be de ne wf zi yl pl gi oi ${
            openFaq && "ud"
          }`}
          // :class="openFaq4 && 'ud' "
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_50_132)">
              <path
                d="M8.82033 1.91065L4.99951 5.73146L1.17869 1.91064L-0.000488487 3.08978L4.99951 8.08978L9.99951 3.08979L8.82033 1.91065Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_50_132">
                <rect
                  width="10"
                  height="10"
                  fill="white"
                  transform="translate(-0.000488281 0.000488281)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
      </button>
      <div
        style={{ display: openFaq ? "block" : "none" }}
        x-show="openFaq"
        className="faq-content"
      >
        <p className="ar bi ii xi ul">{content}</p>
      </div>
    </div>
  );
}
